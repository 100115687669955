var template = document.body.dataset.intendedTemplate;

var readmores = document.querySelectorAll('.readmore');
readmores.forEach(readmore => {
  readmore.onclick = () => {
    var target = readmore.previousElementSibling;
    target.classList.toggle("visible");
    var parent = target.closest('article');
    if(parent){
      parent.scrollIntoView({behavior:'smooth'})
    } else {
      target.scrollIntoView({behavior:'smooth'})
    }
    readmore.textContent = readmore.textContent == "En savoir +" ? "Masquer les détails" : "En savoir +";
  }
});

var menubutton = document.querySelector('#menubutton');
var nav = document.querySelector('.main-nav');
var body = document.body;
menubutton.addEventListener('click', function(){
  if(menubutton.classList.contains('open')){
    nav.classList.remove('open');
    menubutton.classList.remove('open');
    body.classList.remove('open');
    body.scrollIntoView({
      behavior:'smooth'
    })
  } else {
    nav.classList.add('open');
    body.classList.add('open');
    menubutton.classList.add('open');
    nav.scrollIntoView({
      behavior:'smooth'
    })
  }
})

var actives = document.querySelectorAll('strong, .active a, .important');
actives.forEach( (active) => {
  // active.style.setProperty('--r', Math.floor(Math.random() * 10))
  // active.classList.add('rotate-' + Math.floor(Math.random() * 10));
  // active.classList.add('color-' + Math.floor(Math.random() * 10));
})

doMap = function(){
  
  var pau = [ 43.197539, -0.374640 ];
  var arudy = [ 43.102950, -0.430794 ]
  var zoom = 10;
  var map = L.map('map_canvas', {
    attributionControl:false,
    scrollWheelZoom: false
  }).setView(pau, zoom);
  // var layer = new L.StamenTileLayer("toner");
  // map.addLayer(layer);
  var arrow = L.icon({
      iconUrl: 'assets/images/er.svg',
      iconSize:     [166, 70], // size of the icon
      iconAnchor:   [157, 59], // point of the icon which will correspond to marker's location
  });
  var marker = L.marker(arudy, {icon: arrow}).addTo(map);
  // marker.bindPopup('You might be here…');
  marker.addEventListener('click', function(){
    map.setView(arudy, 17);
    // map.panTo([ 46.302618, 2.399459 ])
  });
  var Esri_WorldTopoMap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
  });
  // Esri_WorldTopoMap.addTo(map);
  // var Stadia_AlidadeSmooth = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
  //   maxZoom: 20,
  //   attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
  // });
  // Stadia_AlidadeSmooth.addTo(map);
  var Stamen_TonerLite = L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
    attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: 'abcd',
    minZoom: 0,
    maxZoom: 20,
    ext: 'png'
  });
  Stamen_TonerLite.addTo(map);
};

if(template == "infos"){
  doMap();
}
